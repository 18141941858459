'use client'

import React, { useEffect, useState } from "react"
import { useRouter } from "next/navigation";
import { getCsrfToken, signIn } from "next-auth/react";
import { routes } from "@/routes"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Loader } from "@/common/components/Loader"
import { Grid, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import { FlexBox } from "@/common/components/Box/FlexBox";
import { CredentialsSignin } from "next-auth";

const Login = () => {
  const [csrfToken, setCsrfToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loginFailedError, setLoginFailedError] = useState<string | null>(null)
  const router = useRouter()
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const token = await getCsrfToken();
      setCsrfToken(token as string);
    }
    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginFailedError(null);
    setIsLoading(true);

    const data = new FormData(e.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;

    try {
      const result = await signIn('credentials', {
        email,
        password,
        redirect: false
      });

      if (result?.error === null) {
        router.push(routes.DASHBOARD);
      } else {
        if (result?.error === CredentialsSignin.type) {
          setLoginFailedError('Invalid credentials');
          return
        }

        const isSubdomain = window.location.hostname.split('.').length > 2;

        // Als de login faalt op een subdomain, gaan we ervan uit dat het een workspace access probleem is
        if (isSubdomain) {
          setLoginFailedError("You don't have access to this workspace");
          return
        }
      }
    } catch (err) {
      console.error("Login error:", err);
      setLoginFailedError("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    csrfToken && (
      <FlexBox sx={{ mt: '10%', mx: 'auto', px: 4, maxWidth: 350, textAlign: 'center' }}>
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />

        {isLoading && (
          <FlexBox>
            <Loader isLoading centered />
          </FlexBox>
        )}
        <FlexBox
          sx={{
            marginTop: 8,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form
            method='post'
            onSubmit={handleSubmit}
            style={{ marginTop: 1, opacity: isLoading ? 0.5 : 1 }}
          >
            <FormControl variant='filled' fullWidth margin={'normal'}>
              <InputLabel htmlFor='email'>
                E-mail address
              </InputLabel>
              <FilledInput
                disabled={isLoading}
                required
                fullWidth
                id='email'
                name='email'
                autoComplete='email'
                type='email'
                autoFocus
              />
            </FormControl>
            <FormControl variant='filled' fullWidth margin={'normal'}>
              <InputLabel htmlFor='password'>
                Password
              </InputLabel>
              <FilledInput
                disabled={isLoading}
                required
                fullWidth
                name='password'
                type={showPassword ? "text" : "password"}
                id='password'
                autoComplete='current-password'
                endAdornment={(
                  <InputAdornment sx={{ pr: 1 }} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            {
              loginFailedError &&
              <FlexBox>
                <Alert
                  sx={{ mx: 'auto', mt: 2, width: '100%' }}
                  variant="filled"
                  severity="error"
                >
                  {loginFailedError}
                </Alert>
              </FlexBox>
            }
            <Button
              disabled={isLoading}
              type='submit'
              fullWidth
              size={"large"}
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Button
                sx={{ mx: 'auto' }}
                variant="outlined"
                startIcon={<Google />}
                onClick={(e) => {
                  e.preventDefault()
                  signIn(
                    'google',
                    {
                      redirect: false,
                      // redirectTo: getRoutePath(routes.DASHBOARD),
                    }
                  ).catch(e => console.log(e))
                }}
              >
                Login with Google
              </Button>
            </Grid>

            <br />

            <Grid container>
              {/*<Link href={getRoutePath(routes.FORGOT_PASSWORD)}>
                Forgot password?
              </Link>*/}
              <Button
                onClick={() => router.push(routes.REGISTER)}
                sx={{ mx: 'auto' }}
                variant="outlined"
              >
                Register
              </Button>
            </Grid>
          </form>
        </FlexBox>
      </FlexBox>
    )
  )
}

export default Login
